import styled from 'styled-components';
import { Switch } from 'antd';

const StyledSwitch = styled(Switch)`
    &.ant-switch-checked {
        background-color: ${(p) => p.theme['cyan-2']};
    }
`;

export const DisactiveSpan = styled.span`
    display: inline-block;
    border: 1px solid #0da5ab;
    border-radius: 14px;
    width: 58px;
    color: #0da5ab;
    text-align: center;
`;

export const ActiveSpan = styled.span`
    display: inline-block;
    border: 1px solid #b9b9b9;
    border-radius: 14px;
    width: 58px;
    color: #b9b9b9;
    background-color: #f2f2f2;
    text-align: center;
`;

export default StyledSwitch;
