import { menu_authority } from 'assets/image';
import * as GROUP_PATH from 'router/pathConstants';

export const STORAGE_KEY = {
    TOKEN: 'jwt_t',
};

export const menuItemAll = [
    {
        id: 'KEYWORD',
        icon: menu_authority,
        subMenu: [
            {
                id: 'EXTRACTION',
                href: GROUP_PATH.KEYWORD.EXTRACTION,
                auth: true,
            },
        ],
    },
];

// export const LANG = { 'en-US': 'English', 'zh-TW': '中文（繁體）' };
export const LANG = { 'zh-TW': '中文（繁體）' };
export const DEFAULT_VALUE = '--';

export const typeMapping = {
    managertoday: {
        communication: '商務溝通',
        finance: '財務管理',
        team: '團隊管理',
        coaching: '教練式領導',
        learning: '終身學習',
        production: '生產與供應鏈',
        mbo: '目標管理',
        performance: '績效管理',
        habit: '成功習慣',
        worklife: '工作生活平衡',
        tool: '數位工具',
        sales: '業務銷售',
        service: '服務管理',
        career: '職涯發展',
        project: '產品與專案',
        time: '時間管理',
        relationship: '人際關係',
        decision: '決策技術',
        marketing: '行銷公關',
        leader: '領導力',
        hr: '人力資源',
    },
    iThome: {
        tech: '產品與技術',
        big_data: 'AI',
        cloud: 'Cloud',
        devops: 'DevOps',
        security: '資安',
    },
    businessweekly: {
        business: '財經',
        careers: '職涯',
        english: '英文學習',
        management: '管理',
    },
    gvm: {
        management: '管理',
        career: '職場生活',
    },
    gvlf: {
        workplace: '職場管理',
    },
    trendmicro: {
        info_security: '企業資安',
        for_CISO: 'CISO資安長搶先看',
    },
};

export const locationOptions = [
    { label: '美國', value: 'us' },
    { label: '台灣', value: 'tw' },
    { label: '英國', value: 'uk' },
    { label: '法國', value: 'fr' },
    { label: '德國', value: 'de' },
    { label: '日本', value: 'jp' },
    { label: '韓國', value: 'kr' },
    { label: '加拿大', value: 'ca' },
    { label: '義大利', value: 'it' },
];
