import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button';
import styled from 'styled-components';
import theme from 'styles';

interface CustomButton extends ButtonProps {
    primary?: string;
    secondary?: string;
}

const StyledButton = styled(Button)<CustomButton>`
    &.ant-btn-icon-only[disabled] {
        background: #f2f2f2;
        color: #d4d4d4;
        border: 1px solid #d4d4d4;
        svg {
            stroke: #d4d4d4;
        }
    }

    &.ant-btn {
        border-radius: 5px;
        &.ant-btn-icon-only {
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

        &.is--outline {
            border: 1px solid ${(p) => p.primary || theme.primary};
            background: transparent;
            color: ${(p) => p.primary || theme.primary};
            stroke: ${(p) => p.primary || theme.primary};
        }

        &.is--primary {
            border: 1px solid ${(p) => p.primary || theme.primary};
            background: ${(p) => p.primary || theme.primary};
            color: #fff;
            stroke: #fff;

            &:hover,
            &:focus,
            &:active {
                border: 1px solid ${(p) => p.primary || theme.primary};
                background: ${(p) => p.primary || theme.primary};
                color: #fff;
            }
        }

        &.is--secondary {
            border: 1px solid ${(p) => p.secondary || theme.secondary};
            background: ${(p) => p.secondary || theme.secondary};
            color: #fff;
            stroke: #fff;

            &:hover,
            &:focus,
            &:active {
                border: 1px solid ${(p) => p.secondary || theme.secondary};
                background: ${(p) => p.secondary || theme.secondary};
                color: #fff;
            }
            &:disabled {
                border: 1px solid #bbbbbb;
                background: #cdcdcd;
                color: #fff;
            }
        }

        &.is--disabled {
            background: #f2f2f2;
            color: #d4d4d4;
            border: 1px solid #d4d4d4;

            &:focus,
            &:active,
            &:hover {
                background: #f2f2f2;
                color: #d4d4d4;
                border: 1px solid #d4d4d4;
                cursor: not-allowed;
            }
        }
    }
`;

export default StyledButton;
