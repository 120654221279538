import { Checkbox } from 'antd';
import styled from 'styled-components';

const StyledCheckbox = styled(Checkbox)`
    &.ant-checkbox-wrapper {
        .ant-checkbox-inner {
            width: 22px;
            height: 22px;
            border-radius: 50%;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background: ${(p) => p.theme['cyan-2']};
            border-color: ${(p) => p.theme['cyan-2']};
        }

        .ant-checkbox-checked .ant-checkbox-inner::after {
            left: 6px;
        }
    }
`;

export default StyledCheckbox;
