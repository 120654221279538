import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import { mergeDeepRight } from 'ramda';
import { GlobalContext } from 'store/appStore';
import theme, { injectGlobalStyle } from 'styles';
import Router from './router/router';
import { intl, getMessage, getAntdMessage } from './assets/i18n';
import './App.sass';

const GlobalStyles = injectGlobalStyle();

const App: React.FC = () => {
    const { lang } = useContext(GlobalContext);
    return (
        <ConfigProvider
            locale={{
                ...getAntdMessage(lang),
            }}
        >
            <IntlProvider
                locale={lang}
                key={lang}
                messages={getMessage(lang)}
                defaultLocale="tw"
            >
                <ThemeProvider theme={theme}>
                    <GlobalStyles />
                    <Router />
                </ThemeProvider>
            </IntlProvider>
        </ConfigProvider>
    );
};

export default App;
