/* eslint-disable no-param-reassign */
import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-app-polyfill/ie9';
import AppContextProvider from 'store/appStore';
import * as serviceWorker from './serviceWorker';
import './index.sass';
import App from './App';

const Root = () => (
    <AppContextProvider>
        <App />
    </AppContextProvider>
);

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
