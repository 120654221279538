/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import './UserSelect.sass';
import { Select, TreeSelect } from 'antd';
import { TreeSelectProps } from 'antd/lib/tree-select';

const { TreeNode } = TreeSelect;

const UserSelect: React.FC<TreeSelectProps<any>> = (
    props: TreeSelectProps<any>
) => {
    const intl = useIntl();

    const treeData = [
        {
            title: intl.formatMessage({
                id: 'USER_MGMT.WEB_USER',
            }),
            key: intl.formatMessage({
                id: 'USER_MGMT.WEB',
            }),
        },
        {
            title: intl.formatMessage({
                id: 'USER_MGMT.APP_USER',
            }),
            key: intl.formatMessage({
                id: 'USER_MGMT.APP',
            }),
        },
    ];

    return (
        <TreeSelect
            {...props}
            allowClear
            style={{
                width: '100%',
                borderBottom: '1px solid #d9d9d9',
                padding: '0 0 0 0',
            }}
            bordered={false}
            placeholder={intl.formatMessage({
                id: 'VALIDATION.ACCOUNT_TYPE',
            })}
            dropdownStyle={{
                maxHeight: 400,
                overflow: 'auto',
            }}
            treeDefaultExpandAll
            treeData={treeData}
        />
    );
};

export default UserSelect;
