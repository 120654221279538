// tslint:disable no-bitwise no-var-requires
import {
    map,
    filter,
    addIndex,
    compose,
    either,
    isEmpty,
    not,
    path as Rpath,
    is,
    isNil as RisNil,
} from 'ramda';
import moment from 'moment';
import { apiBaseUrl } from 'constants/config';
import * as PATH from 'router/pathConstants';

const pathToRegex = require('path-to-regex');

export const mapIndexed: any = addIndex(map);

export const filterIndexed: any = addIndex(filter);

export function toHHMM(totalSeconds: number | string) {
    const secNum = parseInt(`${totalSeconds}`, 10);
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor((secNum - hours * 3600) / 60);

    return `${hours < 10 ? `0${hours}` : hours}:${
        minutes < 10 ? `0${minutes}` : minutes
    }`;
}

export const isNil = either(isEmpty, RisNil);

export const hasPath = (path: (string | number)[]) =>
    compose(not, isNil, Rpath(path));

export function toHHMMSS(totalSeconds: number | string) {
    const secNum = parseInt(`${totalSeconds}`, 10);
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor((secNum - hours * 3600) / 60);
    const seconds = secNum - hours * 3600 - minutes * 60;

    return `${hours < 10 ? `0${hours}` : hours}:${
        minutes < 10 ? `0${minutes}` : minutes
    }:${seconds < 10 ? `0${seconds}` : seconds}`;
}

export function uuid() {
    let d = Date.now();
    if (
        typeof performance !== 'undefined' &&
        typeof performance.now === 'function'
    ) {
        d += performance.now();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        // eslint-disable-next-line no-bitwise
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        // eslint-disable-next-line no-bitwise
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
}

export function routeMatch(template: string, pathname: string) {
    // eslint-disable-next-line new-cap
    return new pathToRegex(`${PATH.PREFIX}${template}`).match(pathname);
}

export function copyToClipboard(str: string) {
    const el = document.createElement('textarea'); // Create a <textarea> element
    el.value = str; // Set its value to the string that you want copied
    el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
    el.style.position = 'absolute';
    el.style.left = '-9999px'; // Move outside the screen to make it invisible
    document.body.appendChild(el); // Append the <textarea> element to the HTML document
    const selected =
        (document.getSelection() as any).rangeCount > 0 // Check if there is any content selected previously
            ? (document.getSelection() as any).getRangeAt(0) // Store selection if found
            : false; // Mark as false to know no selection existed before
    el.select(); // Select the <textarea> content
    document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el); // Remove the <textarea> element
    if (selected) {
        // If a selection existed before copying
        (document.getSelection() as any).removeAllRanges(); // Unselect everything on the HTML document
        (document.getSelection() as any).addRange(selected); // Restore the original selection
    }
}

export const getRangeDate = (
    date: [moment.Moment, moment.Moment],
    dateFormat?: string,
    autoSetTime?: boolean
) => {
    if (!date) {
        return [];
    }

    const [startDate, stopDate] = date;

    // eslint-disable-next-line no-shadow
    const setDate = (date: moment.Moment, time: moment.MomentSetObject) => {
        if (!date) {
            return undefined;
        }

        // eslint-disable-next-line no-underscore-dangle
        const _date = date.clone().set(time);

        return dateFormat ? _date.format(dateFormat) : _date.valueOf();
    };

    if (autoSetTime) {
        return [
            setDate(startDate, { hours: 0, minutes: 0, seconds: 0 }),
            setDate(stopDate, { hours: 23, minutes: 59, seconds: 59 }),
        ];
    }
    return [startDate.valueOf(), stopDate.valueOf()];
};

export const getRangeMonth = (
    date: [moment.Moment, moment.Moment],
    dateFormat?: string
) => {
    if (!date) {
        return [];
    }

    const [startDate, stopDate] = date;

    // eslint-disable-next-line no-shadow
    const setDate = (date: moment.Moment, type: string) => {
        if (!date) {
            return undefined;
        }

        // eslint-disable-next-line no-underscore-dangle
        const _date =
            type === 'start'
                ? date.clone().startOf('month')
                : date.clone().endOf('month');

        return dateFormat ? _date.format(dateFormat) : _date.valueOf();
    };

    return [setDate(startDate, 'start'), setDate(stopDate, 'end')];
};

export const formatDate = (
    date: number | moment.Moment | string,
    format: string = 'YYYY/MM/DD'
) => {
    if (!date) {
        return date;
    }

    if (is(moment)(date)) {
        return (date as moment.Moment).format(format);
    }

    return moment(+date).format(format);
};

export const toThousandSeparator = (number: number | string) => {
    if (number && (typeof number === 'number' || Number.isNaN(number as any))) {
        return number.toLocaleString();
    }
    return number;
};

export const exportFile = async ({
    url,
    body,
    filename,
}: {
    url: string;
    body?: { [key: string]: any };
    filename?: string;
}) => {
    const response = await fetch(`${apiBaseUrl}${url}`, {
        method: 'POST',
        headers: {
            authorization: localStorage.getItem('token') || '',
        },
        body: JSON.stringify(body),
        // eslint-disable-next-line no-shadow
    }).then((response) => response.blob());

    downloadFile({ blob: response, filename });
};

export const downloadFile = ({
    blob,
    filename,
}: {
    blob: Blob;
    filename?: string;
}) => {
    const a = document.createElement('a');
    const objectUrl = window.URL.createObjectURL(blob);

    a.href = objectUrl;
    a.download = `${filename}.xlsx`;
    a.click();

    return blob;
};

export const isJson = (str: string) => {
    try {
        return JSON.parse(str.replace(/(\r\n|\n|\r)/gm, ''));
    } catch (e) {
        return false;
    }
};

export const getBase64 = (
    img: File | Blob | undefined,
    callback: (url: string | ArrayBuffer | null) => any
) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    if (img) {
        reader.readAsDataURL(img);
    }
};

export const htmlencode = (s: string) => {
    const div = document.createElement('div');
    div.appendChild(document.createTextNode(s));
    return div.innerHTML;
};

export const htmldecode = (s: string) => {
    const div = document.createElement('div');
    div.innerHTML = s;
    return div.innerText || div.textContent;
};

export const getMqttUrlReg = (url: string): RegExpExecArray | null => {
    try {
        const mqttUrlRegexp = /((mqtts?|wss?):\/\/)(([^:^/]*)(:\\d*)?(.*)?)/g;
        const match = mqttUrlRegexp.exec(url);
        return (match?.length || 0) >= 4 ? match : null;
    } catch {
        return null;
    }
};

export const pad2 = (number: number) => (number < 10 ? '0' : '') + number;

export const getTime = (duration: number) => {
    const hours = pad2(Math.floor((duration / (1000 * 60 * 60)) % 24));
    const minutes = pad2(Math.floor((duration / 1000 / 60) % 60));
    const seconds = pad2(Math.floor((duration / 1000) % 60));
    return { hours, minutes, seconds };
};

export const getMutiJsonString = (string: string) => {
    const regArr = /.*({.*}).*/gm.exec(string);
    if (regArr && regArr.length > 1) {
        return regArr;
    }
    return ['', ''] as RegExpExecArray;
};

export const download = (filename: string, text: string) => {
    const element = document.createElement('a');
    element.setAttribute(
        'href',
        `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`
    );
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};
