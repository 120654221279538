module.exports = {
    blue1: '#1890ff',
    blue2: '#1890ff',

    yellow1: '#E3B004',

    red1: '#ff4d4f',

    gray1: '#535353',
    gray2: '#AEADA8',
    gray3: '#f2f2f2',
    gray4: '#dedede',

    cyan1: '#C1D9DF',
    cyan2: '#BBDAE0',
    cyan3: '#0DA5AB',
    cyan4: '#4fd4ca',

    orange1: '#F29423',
    white: '#FFFFFF',
};
