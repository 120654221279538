import PATH from 'router/pathConstants';

const DEFAULT_MENU_SAMPLE = {
    key: 'MENU_KEY', // 主頁ID, 保持唯一
    i18n: 'MENU.MAIN_PAGE', // 主頁i18n
    icon: null, // 主頁圖檔
    path: null, // 主頁要跳轉的PATH, TODO: 目前一定要有分頁.
    menu: [
        // 分頁內容
        {
            key: 'SUB_MENU_KEY', // 分頁ID, 保持唯一
            i18n: 'MENU.SUB_MAIN_PAGE1', // 分頁i18n
            icon: null, // 分頁圖檔
            path: PATH.OVERVIEW, // 分頁點選後要跳轉的PATH
            relativePaths: [], // 該分頁下會出現的所有PATH, 如果要控制權限的話要列出來, 當權限不合會自動跳轉到該權限的第一個合法PATH
            isFirstPage: true,
        },
    ],
};
const DEFAULT_MENU_SAMPLE2 = {
    key: 'MENU_KEY2', // 主頁ID, 保持唯一
    i18n: 'MENU.MAIN_PAGE', // 主頁i18n
    icon: null, // 主頁圖檔
    path: PATH.OVERVIEW, // 主頁要跳轉的PATH
    menu: [],
};

const MENU_FRAME = {
    key: 'KEYWORD',
    i18n: 'MENU.KEYWORD',
    icon: null,
    path: null,
    menu: [
        {
            key: 'KEYWORD_HISTORY',
            i18n: 'MENU.KEYWORD_HISTORY',
            icon: null,
            path: PATH.HISTORY,
            relativePaths: [],
            isFirstPage: true,
        },
        {
            key: 'KEYWORD_EXTRACTION',
            i18n: 'MENU.KEYWORD_EXTRACTION',
            icon: null,
            path: PATH.EXTRACTION,
            relativePaths: [],
            isFirstPage: true,
        },
        {
            key: 'KEYWORD_RECORD',
            i18n: 'MENU.KEYWORD_RECORD',
            icon: null,
            path: PATH.RECORD,
            relativePaths: [],
            isFirstPage: true,
        },
    ],
};

const ANALYSIS = {
    key: 'ANALYSIS', // 主頁ID, 保持唯一
    i18n: 'MENU.ANALYSIS', // 主頁i18n
    icon: null, // 主頁圖檔
    path: PATH.ANALYSIS, // 主頁要跳轉的PATH
    menu: [
        {
            key: 'TEXT_ANALYSIS',
            i18n: 'MENU.ANALYSIS',
            icon: null,
            path: PATH.TEXT_ANALYSIS,
            relativePaths: [],
            isFirstPage: true,
        },
        // {
        //     key: 'TEXT_ANALYSIS_LIST',
        //     i18n: 'MENU.ANALYSIS_LIST',
        //     icon: null,
        //     path: PATH.TEXT_ANALYSIS_LIST,
        //     relativePaths: [],
        //     isFirstPage: true,
        // },
    ],
};

const DASHBOARD = {
    key: 'DASHBOARD', // 主頁ID, 保持唯一
    i18n: 'MENU.DASHBOARD', // 主頁i18n
    icon: null, // 主頁圖檔
    path: PATH.DASHBOARD_OVERVIEW, // 主頁要跳轉的PATH
    menu: [
        {
            key: 'OVERVIEW',
            i18n: 'MENU.DASHBOARD_OVERVIEW',
            icon: null,
            path: PATH.DASHBOARD_OVERVIEW,
            relativePaths: [],
            isFirstPage: true,
        },
        {
            key: 'KEYWORD',
            i18n: 'MENU.DASHBOARD_KEYWORD',
            icon: null,
            path: PATH.DASHBOARD_KEYWORD,
            relativePaths: [],
            isFirstPage: true,
        },
        {
            key: 'PARTNER',
            i18n: 'MENU.DASHBOARD_PARTNER',
            icon: null,
            path: PATH.DASHBOARD_PARTNER,
            relativePaths: [],
            isFirstPage: true,
        },
    ],
};

const DATABASE = {
    key: 'DATABASE',
    i18n: 'MENU.DATABASE',
    icon: null,
    path: null,
    menu: [
        {
            key: 'DATABASE',
            i18n: 'MENU.DATABASE',
            icon: null,
            path: PATH.DATABASE,
            relativePaths: [],
            isFirstPage: true,
        },
    ],
};
const SQM = {
    key: 'SQM',
    i18n: 'MENU.SQM',
    icon: null,
    path: null,
    menu: [
        {
            key: 'SQM',
            i18n: 'MENU.SQM',
            icon: null,
            path: PATH.SQM,
            relativePaths: [],
            isFirstPage: true,
        },
    ],
};

const BACKTEST_ANALYSIS = {
    key: 'BACKTEST_ANALYSIS',
    i18n: 'MENU.BACKTEST_ANALYSIS',
    icon: null,
    path: null,
    menu: [
        {
            key: 'BACKTEST_ANALYSIS',
            i18n: 'MENU.BACKTEST_ANALYSIS',
            icon: null,
            path: PATH.BACKTEST_ANALYSIS,
            relativePaths: [],
            isFirstPage: true,
        },
    ],
};
/**
 * 固定腳色權限表
 * 腳色對應權限內容如果固定的話直接定義在這邊, 如果是可客製的部分, 不須定義在這邊
 */
const FIXED_ROLE_MENU = {
    ROLES: ['GUEST', 'MANAGER'], // 表列所有固定腳色

    MANAGER: {
        // 腳色
        MANAGER0: ['DATABASE'],
        MANAGER: ['KEYWORD_EXTRACTION', 'KEYWORD_RECORD'],
        MANAGER2: ['ANALYSIS'],
        MANAGER3: ['TEXT_ANALYSIS', 'TEXT_ANALYSIS_LIST'],
        MANAGER4: ['KEYWORD_EXTRACTION', 'KEYWORD_RECORD'],
        DASHBOARD: ['OVERVIEW', 'KEYWORD', 'PARTNER'],
        SQM: ['SQM'],
        BACKTEST_ANALYSIS: ['BACKTEST_ANALYSIS'],
    },
    GUEST: {
        DASHBOARD: ['OVERVIEW', 'KEYWORD', 'PARTNER'],
        BACKTEST_ANALYSIS: ['BACKTEST_ANALYSIS'],
    },
};

/**
 * 目前系統內會用到的所有MENU, 之後可以搭配ROLE
 */
const MENU_POOL = {
    // key請確保
    MANAGER0: DATABASE,
    MANAGER: MENU_FRAME,
    MANAGER3: ANALYSIS,
    DASHBOARD,
    SQM,
    BACKTEST_ANALYSIS,
    // SAMPLE_MANAGER: DEFAULT_MENU_SAMPLE,
    // SAMPLE_MANAGER2: DEFAULT_MENU_SAMPLE2,
};

/**
 * @param role 如果是固定腳色, 直接帶腳色
 * @param privileges 如果是訂製權限, 則不需要帶腳色
 * @returns
 */
export const getMenu = (role: string, privileges: any) => {
    console.log('role', role);
    console.log('FIXED_ROLE_MENU.ROLES', FIXED_ROLE_MENU.ROLES);
    let privilege = {};
    // 判斷是否為固定腳色群
    if (FIXED_ROLE_MENU.ROLES.indexOf(role) > -1) {
        privilege = FIXED_ROLE_MENU[role];
        console.log('privilege', privilege);
    } else {
        // 非固定腳色群, 判斷privileges, 選單順序以MENU_POOL為主
        Object.keys(MENU_POOL).forEach((level1: string) => {
            if (privileges[level1]) {
                privilege = { ...privilege };
                privilege[level1] = MENU_POOL[level1].menu
                    .map((subObj: any) => {
                        if (
                            privileges[level1].find(
                                (v: string) => subObj.key === v
                            )
                        ) {
                            return subObj.key;
                        }
                        return undefined;
                    })
                    .filter((v: string) => v !== undefined);
            }
        });
    }
    // convert from menu pool
    let firstPage = '';
    const menus = Object.keys(privilege)
        .map((key1: string) => {
            if (MENU_POOL[key1]) {
                const level2 = MENU_POOL[key1].menu.filter((l2Menu: any) => {
                    if (privilege[key1].indexOf(l2Menu.key) > -1) {
                        if (firstPage === '' && l2Menu.path !== '') {
                            firstPage = l2Menu.path;
                        }
                        return true;
                    }
                    return false;
                });
                if (level2.length > 0) {
                    return {
                        ...MENU_POOL[key1],
                        menu: level2,
                    };
                }
                return {
                    ...MENU_POOL[key1],
                    menu: [],
                };
            }
            return undefined;
        })
        .filter((v) => v !== undefined);
    return {
        firstPage,
        menus,
        privileges: privilege,
    };
};
export default MENU_POOL;
