// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import { Input } from 'components/Input';
import { warning } from 'utils/message';
import Button from '../Button/Button';
import Drawer from '../Drawer/Drawer';

interface ChangePasswordDrawerProps {
    onClose: any;
    visable: boolean;
}

const ChangePasswordDrawer: React.FC<ChangePasswordDrawerProps> = ({
    onClose,
    visable,
}) => {
    const intl = useIntl();
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [visable]);

    const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/;

    const handleOk = () => {
        form.validateFields().then((values) => {
            warning({
                content: (
                    <div>
                        <div className="mb-30 text--xl">
                            {intl.formatMessage({
                                id: 'COMMON.CHANGE_PASSWORD',
                            })}
                        </div>
                        {intl.formatMessage({
                            id: 'COMMON.CHANGE_PASSWORD_CONTENT',
                        })}
                    </div>
                ),
                onOk: async () => {},
                onCancel() {},
            });
        });
    };

    return (
        <Drawer
            getContainer={false}
            width={525}
            title={intl.formatMessage({
                id: 'COMMON.CHANGE_PASSWORD',
            })}
            footer={
                <div className="pull--right">
                    <Button className="mr-20" onClick={onClose}>
                        {intl.formatMessage({ id: 'COMMON.CANCEL' })}
                    </Button>
                    <Button className="is--primary" onClick={handleOk}>
                        {intl.formatMessage({ id: 'COMMON.CONFIRM' })}
                    </Button>
                </div>
            }
            onClose={onClose}
            visible={visable}
        >
            <div>
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="oldPassword"
                        required
                        label={intl.formatMessage({
                            id: 'COMMON.OLD_PASSWORD',
                        })}
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'VALICATION.OLD_PASSSWORD',
                                }),
                            },
                        ]}
                    >
                        <Input
                            type="password"
                            bordered={false}
                            placeholder={intl.formatMessage({
                                id: 'PLACEHOLDER.OLD_PASSSWORD',
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        required
                        label={intl.formatMessage({
                            id: 'COMMON.NEW_PASSWORD',
                        })}
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'VALICATION.NEW_PASSWORD',
                                }),
                            },
                            {
                                validator: (_: any, value: any) => {
                                    if (value && !passwordRegex.test(value)) {
                                        return Promise.reject(
                                            intl.formatMessage({
                                                id: 'VALICATION.PASSWORD_FORMAT',
                                            })
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <Input
                            type="password"
                            bordered={false}
                            placeholder={intl.formatMessage({
                                id: 'PLACEHOLDER.NEW_PASSWORD',
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        name="rePassword"
                        required
                        label={intl.formatMessage({
                            id: 'COMMON.NEW_PASSWORD_CONFIRM',
                        })}
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'VALICATION.NEW_PASSWORD_CONFIRM',
                                }),
                            },
                            {
                                validator: (_: any, value: any) => {
                                    if (
                                        value &&
                                        value !== form.getFieldValue('password')
                                    ) {
                                        return Promise.reject(
                                            intl.formatMessage({
                                                id: 'VALICATION.PASSWORD_DIFF',
                                            })
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <Input
                            type="password"
                            bordered={false}
                            placeholder={intl.formatMessage({
                                id: 'PLACEHOLDER.NEW_PASSWORD_CONFIRM',
                            })}
                        />
                    </Form.Item>
                </Form>
            </div>
        </Drawer>
    );
};

export default ChangePasswordDrawer;
