import React from 'react';
import { Input } from 'antd';
import { InputProps, TextAreaProps } from 'antd/es/input';
import styled, { css } from 'styled-components';

const borderLess = css`
    padding-left: 1px;
    border: none;
    border-bottom: 1px solid #d9d9d9;
    outline: none;
    box-shadow: none;

    &:hover,
    &:active,
    &:focus {
        outline: none;
        box-shadow: none;
    }
`;

const StyledInput = styled(({ bordered, ...props }) => (
    <Input {...props} />
))<Props>`
    &.ant-input,
    &.ant-input-affix-wrapper {
        ${borderLess};

        border-bottom: ${(props) => (props.readOnly ? 'none' : '')};
        border-top: ${(props) => (props.readOnly ? '1px solid #D4D4D4' : '')};

        &.ant-input[disabled] {
            color: ${(p) => p.theme.grey};
        }

        &.border-bottom--cyan {
            bottom: -3px;
            padding-bottom: 10px;
            border-top: none;
            border-bottom: 1px solid ${(p) => p.theme['cyan-4']} !important;
        }

        &.border-bottom--hide {
            border-bottom: none !important;
        }
    }

    &.ant-input-affix-wrapper {
        ${borderLess};
    }
`;

const Password = styled(({ bordered, ...props }) => (
    <Input.Password {...props} />
))<Props>`
    &.ant-input,
    &.ant-input-affix-wrapper {
        ${borderLess};

        border-bottom: ${(props) => (props.readOnly ? 'none' : '')};
        border-top: ${(props) => (props.readOnly ? '1px solid #D4D4D4' : '')};
    }

    &.ant-input-affix-wrapper {
        ${borderLess};
    }
`;

const Search = styled(({ bordered, ...props }) => <Input.Search {...props} />)`
    &.ant-input-affix-wrapper {
        ${(p) =>
            !p.bordered
                ? css`
                      ${borderLess};
                      background: transparent;

                      input {
                          background: transparent;
                      }
                  `
                : null};
    }
`;

type Props = InputProps & {
    bordered?: boolean;
};

const TextArea = styled(({ bordered, ...props }) => (
    <Input.TextArea {...props} />
))<TextAreaProps & { bordered?: boolean }>`
    &.ant-input {
        ${borderLess};
        resize: auto !important;
    }
`;

class InputComponent extends React.Component<Props, any> {
    public static TextArea = Input.TextArea;

    public static Password = Input.Password;

    public static Group = Input.Group;

    public static Search = Input.Search;

    render() {
        const { bordered = true, ...props } = this.props;

        return <StyledInput bordered={bordered} {...props} />;
    }
}

export { InputComponent as Input, TextArea, Search, Password };
