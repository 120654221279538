import styled from 'styled-components';
import { Form } from 'antd';

const StyledForm = styled(Form)`
    .ant-form-item-has-error .ant-input-affix-wrapper,
    .ant-form-item-has-error .ant-picker {
        border-bottom: 1px solid #e85050 !important;
    }

    .ant-form-item-has-error.error--half .ant-form-item-explain div {
        width: 50%;
        display: inline-block;
    }

    .ant-form-item-has-error.error--half
        .ant-form-item-explain
        div:nth-child(2) {
        padding-left: 6px;
    }
`;

export { StyledForm as Form };
