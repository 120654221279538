import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import RouterGuard from './guard';

const Router: React.FC = () => {
    const component = (
        <BrowserRouter
            basename={
                process.env.REACT_APP_PUBLIC_URL
                    ? process.env.REACT_APP_PUBLIC_URL
                    : ''
            }
        >
            <Switch>
                <Route>
                    <RouterGuard />
                </Route>
            </Switch>
        </BrowserRouter>
    );
    return component;
};

export default Router;
