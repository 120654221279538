import { hasPath } from 'ramda';

export const apiCaller = (
    caller: any,
    body: any = null,
    success: any,
    reject: any
) =>
    body
        ? caller(body)
              .then((res: any) => success(res))
              .catch((error: any) => reject(error))
        : caller()
              .then((res: any) => success(res))
              .catch((error: any) => reject(error));

export const apiCallerNew = (
    caller: any,
    body: any = null,
    success: any,
    reject: any
) =>
    body
        ? caller(body)
              .then((res: any) => {
                  if (res) {
                      success({
                          status: res.status,
                          data: res.data ? res.data : res.data?.data,
                          total: res.total,
                          success: res.success,
                      });
                  } else {
                      reject({
                          status: 500,
                          data: null,
                          msg: 'server no response!!!',
                      });
                  }
              })
              .catch((error: any) => reject(error))
        : caller()
              .then((res: any) => success(res))
              .catch((error: any) => reject(error));

export const getData = (apiConfig: any, body: any): any => {
    // eslint-disable-next-line consistent-return
    const success = (res: any) => {
        if (res.status >= 200 && res.status < 300) {
            return res.data;
        }
    };
    const reject = (error: any) => null;
    return apiCaller(apiConfig, body, success, reject);
};

export const dataParser = <T>(promx: any, parser: any) =>
    new Promise((resolve, reject) => {
        promx.then(
            (res: any) => {
                // Data parser
                if (hasPath(['data', 'data', 'data'], res)) {
                    res.data.data.data.forEach((r: any) => parser(r));
                } else if (hasPath(['data', 'data'], res)) {
                    res.data.data.forEach((r: any) => parser(r));
                }

                resolve(res);
            },
            (err: any) => {
                reject(err);
            }
        );
    });

// export const strongTypeParser = <T>(promx: any, parser: any) => {
//     return new Promise((resolve, reject) => {
//         promx.then((res: any) => {
//             // Data parser
//             if (hasPath(['data', 'data', 'data'], res)) {
//                 res.data.data.data = strongType<T>(res.data.data.data);
//             }
//             else if (hasPath(['data', 'data'], res)) {
//                 // res.data.data.forEach((r: any) => parser(r));
//                 const rx = strongType<T>(res.data.data);
//                 res.data.data = rx;
//             }

//             resolve(res);
//         }, (err: any) => {
//             reject(err);
//         });
//     });
// }

// type MakePick<Type, Keys extends keyof Type> = { [Key in Keys]: Type[Key] };

// function prop<T, K extends keyof T>(obj: T, key: K) {
//     return obj[key];
// }

// // function foo<T,

// const strongType = <T>(rows: any[]) => {
//     let ar: T[] = [];
//     rows.forEach((r: any) => {
//         ar.push({...r});
//     });
//     return ar;
// }
