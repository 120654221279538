import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Drawer from 'components/Drawer/Drawer';
import Button from 'components/Button/Button';
import { Form, Upload, Progress } from 'antd';
import { error, confirm } from 'utils/message';
import { pathOr } from 'ramda';
import Icon, { InboxOutlined } from '@ant-design/icons'; // tslint:disable-line
import {
    UploadChangeParam,
    UploadFile,
    RcFile,
} from 'antd/lib/upload/interface';
import { IconWarning } from 'assets/image';
import { DrawerProps } from 'antd/lib/drawer';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ErrorType } from 'constants/types';
import ProgressModal from '../Modal/ProgressModal';

const { Dragger } = Upload;

interface ImportDrawerProps extends DrawerProps {
    onSave: () => any | void;
    onCancel: () => any;
    subtitle: any;
    uploadApi: (
        file: File | Blob | undefined,
        config: AxiosRequestConfig
    ) => Promise<AxiosResponse<any>>;
    exampleUrl?: string;
}

export default function ImportDrawer({
    onSave,
    onCancel,
    uploadApi,
    subtitle,
    ...props
}: ImportDrawerProps) {
    let isCsv = false;
    const [form] = Form.useForm();
    const intl = useIntl();

    const [progressShow, setProgressShow] = useState(false);
    const [progressNumber, setProgressNumber] = useState(0);

    const [fileList, setFileList] = useState<Array<UploadFile>>([]);

    const handleChange = (info: UploadChangeParam) => {
        let flist = [...info.fileList];
        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        flist = flist.slice(-1);

        const nextFileList = isCsv ? flist : [];
        setFileList(nextFileList);
        form.setFieldsValue({ vehicleImportFile: nextFileList });
    };

    const handleUploadProgress = (progressEvent: any) => {
        setProgressNumber(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
    };

    const resetAll = () => {
        setProgressShow(false);
        setProgressNumber(0);
        setFileList([]);
    };

    const handleSave = () => {
        resetAll();
        onSave();
    };

    const handleCancel = () => {
        resetAll();
        onCancel();
    };

    const saveFile = async () => {
        console.log(fileList);
        if (fileList.length > 0) {
            setProgressShow(true);
            try {
                await uploadApi(fileList[0].originFileObj, {
                    onUploadProgress: handleUploadProgress,
                });
                confirm({
                    width: 489,
                    icon: (
                        <Progress
                            type="circle"
                            percent={100}
                            style={{ margin: '20px 0' }}
                            width={220}
                            status="normal"
                            format={() => '100%'}
                        />
                    ),
                    content: (
                        <div
                            style={{
                                fontSize: '24px',
                                letterSpacing: '0px',
                                color: '#535353',
                                margin: '20px 0',
                            }}
                        >
                            {intl.formatMessage({
                                id: 'VEHICLE_TYPE.IMPORT_DONE',
                            })}
                        </div>
                    ),
                    onOk: handleSave,
                });
            } catch (e) {
                const err = e as ErrorType;
                if (err?.status === 400) {
                    const errList = JSON.parse(err?.data?.errorDesc || '');
                    confirm({
                        width: 489,
                        icon: (
                            <Icon
                                component={IconWarning}
                                className="mb-20 d-block"
                            />
                        ),
                        content: (
                            <div>
                                <div className="mb-30 text--xl">
                                    {intl.formatMessage({
                                        id: 'COMMON.IMPORT_ERROR',
                                    })}
                                </div>
                                <div
                                    style={{
                                        color: '#808996',
                                        marginTop: '13px',
                                        height: '29px',
                                    }}
                                >
                                    {intl.formatMessage(
                                        { id: 'COMMON.IMPORT_TOTAL_NUM' },
                                        { total: errList.length }
                                    )}
                                </div>
                                <div
                                    style={{
                                        border: '1px solid #707070',
                                        padding: '13px',
                                        color: '#808996',
                                        maxHeight: '118px',
                                        overflowY: 'scroll',
                                    }}
                                >
                                    {errList.map((values: number) => (
                                        <div>
                                            {intl.formatMessage(
                                                {
                                                    id: 'COMMON.IMPORT_ERROR_ROW',
                                                },
                                                { number: values }
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ),
                        onOk: handleCancel,
                    });
                } else {
                    error({
                        content: intl.formatMessage({
                            id: pathOr(
                                'SERVICE_ERROR.ERROR',
                                ['data', 'errorMsg'],
                                err
                            ),
                        }),
                    });
                }
            } finally {
                setProgressShow(false);
            }
        }
    };

    const handleBeforeUpload = (file: RcFile) => {
        // 限制格式為 csv
        if (/\.(csv)$/.test(file.name)) {
            isCsv = true;
        }

        if (!isCsv) {
            error({
                content: intl.formatMessage({ id: 'VALIDATION.FILE_CSV' }),
            });
        }
        return isCsv;
    };

    return (
        <Drawer
            width="50%"
            onClose={handleCancel}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
                <div className="pull--right">
                    <Button className="mr-20" onClick={handleCancel}>
                        {intl.formatMessage({ id: 'COMMON.CANCEL' })}
                    </Button>
                    <Button className="is--primary" onClick={form.submit}>
                        {intl.formatMessage({ id: 'COMMON.IMPORT' })}
                    </Button>
                </div>
            }
            {...props}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={saveFile}
                requiredMark={false}
            >
                <div className="d-flex is--space-between mb-8">
                    <div className="text--cyan-2 mb-16">{subtitle}</div>
                    <span>
                        <a href={props.exampleUrl}>
                            <Button className="is--primary ml-16">
                                {intl.formatMessage({
                                    id: 'COMMON.DOWNLOAD_EXAMPLE',
                                })}
                            </Button>
                        </a>
                    </span>
                </div>
                <div style={{ display: 'flex' }}>
                    <Form.Item
                        className="mr-8"
                        style={{ width: 'calc(100%)' }}
                        label={intl.formatMessage({
                            id: 'VEHICLE_TYPE.IMPORT_FILE',
                        })}
                        name="vehicleImportFile"
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'VALIDATION.FILE',
                                }),
                            },
                        ]}
                    >
                        <Dragger
                            multiple={false}
                            customRequest={({
                                onSuccess,
                                file,
                            }: {
                                onSuccess?: any;
                                file: any;
                            }) => {
                                setTimeout(() => {
                                    onSuccess({ result: 'ok' }, file);
                                }, 0);
                            }}
                            fileList={fileList}
                            onChange={handleChange}
                            beforeUpload={handleBeforeUpload}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                                <FormattedMessage id="VEHICLE_TYPE.ADD_FILE" />
                            </p>
                            <p className="ant-upload-hint">
                                <FormattedMessage id="VEHICLE_TYPE.ONCE" />
                            </p>
                        </Dragger>
                    </Form.Item>
                </div>
            </Form>
            <ProgressModal
                visible={progressShow}
                progressNumber={progressNumber}
            />
        </Drawer>
    );
}
