import React from 'react';
import { Drawer } from 'antd';
import { DrawerProps } from 'antd/es/drawer';
import styled from 'styled-components';
import bgHeader from '../../assets/image/pic/bg_header.svg';

const StyledDrawer: any = styled(Drawer)`
    .ant-drawer-header,
    .ant-drawer-body,
    .ant-drawer-footer {
        padding-left: 45px;
        padding-right: 45px;
    }

    .ant-drawer-header {
        background: url(${bgHeader}) no-repeat center;
        background-size: 110% 100%;
        height: 50px;
    }

    .ant-drawer-body {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .ant-drawer-close,
    .ant-drawer-title {
        color: #fff;
    }

    .ant-drawer-footer {
        padding-top: 15px;
        padding-bottom: 24px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background: ${(p) => p.theme['checkbox-color']};
        border-color: ${(p) => p.theme['checkbox-color']};
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        background: ${(p) => p.theme['checkbox-color']};
    }

    .ant-form-vertical .ant-form-item-label {
        padding-bottom: 4px;
    }

    textarea.ant-input {
        resize: none;
    }
`;

type Props = DrawerProps & {
    children?: React.ReactNode;
};

export default function DrawerComponent(props: Props) {
    return <StyledDrawer {...props} />;
}
