import React from 'react';
import styled from 'styled-components';
import { css } from '@emotion/core';
import { FadeLoader } from 'react-spinners';

const Loadingspin = styled.div`
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 20000;
`;

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
    display: block;
    margin: 0 auto;
    position: absolute;
    top: calc(50vh - 30px);
    left: calc(50vw - 30px);
`;

const LoadingBar = () => (
    <Loadingspin id="loadingspin">
        <FadeLoader css={override} color="#123abc" loading />
    </Loadingspin>
);

export default LoadingBar;
