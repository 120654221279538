import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import styled from 'styled-components';
import { Button, Tooltip } from 'antd';
import theme from 'styles';

interface ActionIconButtonProps extends ButtonProps {
    selected: boolean;
    title: string;
}

const StyledButton = styled(Button)`
    border: 2px solid #fff;
    background: #acaba6;
    color: #fff;

    &:hover,
    &:focus,
    &:active {
        border: 2px solid #fff;
        background: #1890ff;
        color: #fff;
    }
    &.selected {
        background: #1890ff;
        color: #fff;
        border: 2px solid #fff;
        &:hover,
        &:focus,
        &:active {
            border: 2px solid #fff;
            background: #1890ff;
            color: #fff;
        }
    }
`;

export default function ActionIconButton({
    title,
    selected,
    className,
    ...props
}: ActionIconButtonProps) {
    return (
        <Tooltip placement="top" title={title}>
            <StyledButton
                shape="circle"
                className={`${selected ? 'selected' : ''} ${className}`}
                {...props}
            />
        </Tooltip>
    );
}
