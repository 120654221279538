import React, { useState, useEffect } from 'react';
import { GlobalContextType, FitConfig } from 'types/GlobalContextType';
import { T_UserInfo } from 'types/UserInfo';
import { STORAGE_KEY } from 'constants/index';

export const GlobalContext = React.createContext<GlobalContextType>(
    {} as GlobalContextType
);

type AppcontextProps = {};
const AppContextProvider: React.FC<AppcontextProps> = ({ children }) => {
    // https://kentcdodds.com/blog/should-i-usestate-or-usereducer
    // 設定GlobalContext也可以用useReducer
    const [permissionCtx, setPermissionCtx] = useState({});
    const [domainData, setDomainData] = useState([]);
    const [userInfo, setUserInfo] = useState<T_UserInfo>({});
    const [closableMenu, setClosableMenu] = useState(false);
    const [visibleMenu, setVisibleMenu] = useState(false); // closableMenu = true才會有作用
    const [lang, setLang] = useState(
        localStorage.getItem('language') || 'zh-TW'
    );
    const [configData, setConfigData] = useState<FitConfig>({});

    useEffect(() => {
        localStorage.setItem('language', lang);
    }, [lang]);

    const changeClosable = (bool: boolean) => {
        if (!bool) {
            setVisibleMenu(false);
        }
        setClosableMenu(bool);
    };

    const login = (user: T_UserInfo) => {
        setUserInfo(user);
        localStorage.setItem(STORAGE_KEY.TOKEN, user.token ? user.token : '');
    };

    const logout = () => {
        setUserInfo({});
        localStorage.removeItem(STORAGE_KEY.TOKEN);
    };

    return (
        <GlobalContext.Provider
            value={{
                permissionCtx,
                setPermissionCtx,
                closableMenu,
                setClosableMenu: changeClosable,
                visibleMenu,
                setVisibleMenu,
                lang,
                setLang,
                configData,
                setConfigData,
                userInfo,
                setUserInfo,
                login,
                logout,
                domainData,
                setDomainData,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export default AppContextProvider;
