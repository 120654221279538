/* eslint-disable jsx-a11y/aria-role */
import React, { useState, useEffect, useContext } from 'react';
import { Popover, List, Tooltip, Tabs } from 'antd';
import { DownOutlined, HomeOutlined, SettingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { GlobalContext } from 'store/appStore';
import { SVGIconAccount, XTrendLogo } from 'assets/image';
import { error } from 'utils/message';
import * as PATH from 'router/pathConstants';
import { LANG, menuItemAll } from 'constants/index';
import { Button, Navigation } from '..';
import { apiCaller } from '../../service/apiCaller';
import './Header.sass';
// import { View, Image, Text, StyleSheet } from "react-native";

const Header: React.FC<any> = (props: any) => {
    const { TabPane } = Tabs;
    const intl = useIntl();
    const history = useHistory();
    const { lang, setLang, logout } = useContext(GlobalContext);
    const [userInfo, setUserInfo] = useState({
        name: '',
        role: '',
        roleName: '',
        managingOrganization: '',
        tenantName: '',
    });
    useEffect(() => {}, []);

    const getLangList = () => (
        <List
            dataSource={Object.keys(LANG).map((lan) => ({
                key: lan,
                label: LANG[lan],
            }))}
            renderItem={(item) => (
                <List.Item
                    className="lang-list-item"
                    key={item.key}
                    onClick={() => setLang(item.key)}
                >
                    {item.label}
                </List.Item>
            )}
        />
    );

    const domain = window.location.pathname.split('/');
    // const domainKey = PATH.keyMap[`/${domain[2]}`];
    const domainKey = PATH.keyMap[`/${domain[1]}/${domain[2]}`];
    // console.log(domainKey);
    let tabKey = '';
    menuItemAll.forEach((val: any, index, array) => {
        if (domainKey === val.id) {
            tabKey = index.toString();
        }
    });

    function callback(key: any) {
        console.log('Header click: ', key);
    }

    return (
        <header className="header_title">
            <img className="header_logo" src={XTrendLogo} alt="" />
            <div>
                {props.needAuth && (
                    <>
                        <Tooltip
                            title={
                                <div
                                    onClick={props.onClickChangePassword}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <FormattedMessage id="COMMON.CHANGE_PASSWORD" />
                                </div>
                            }
                            color="#fff"
                            trigger="click"
                            overlayInnerStyle={{ color: '#000' }}
                        >
                            <img
                                src={SVGIconAccount}
                                alt=""
                                style={{ cursor: 'pointer' }}
                            />
                        </Tooltip>
                        <span className="header_userName ml-8">
                            {userInfo.name}
                        </span>
                        <span className="header_userName">
                            {`${userInfo.tenantName} / ${userInfo.roleName}`}
                        </span>
                    </>
                )}
                <Popover content={getLangList()}>
                    <Button
                        type="text"
                        className="header_userName"
                        style={{ color: 'white' }}
                    >
                        {LANG[lang]}
                        <DownOutlined style={{ color: 'white' }} />
                    </Button>
                </Popover>
                {props.needAuth && (
                    <Button className="logout_button ml-16" onClick={logout}>
                        <FormattedMessage id="COMMON.LOGOUT" />
                    </Button>
                )}
            </div>
        </header>
    );
};

export default Header;
