import React, { useContext } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { STORAGE_KEY } from 'constants/index';

const isAxiosError = (error: any) => error.isAxiosError;

export const api = axios.create({
    // baseURL: 'http://192.168.1.13/backend/',
    baseURL: process.env.REACT_APP_IP,
    // baseURL: process.env.REACT_APP_JSON_SERVER_URL,
    timeout: 60000,
});

api.interceptors.request.use((Request) => {
    localStorage.getItem(STORAGE_KEY.TOKEN) &&
        // eslint-disable-next-line no-param-reassign
        (Request.headers.Authorization = localStorage.getItem(
            STORAGE_KEY.TOKEN
        ));
    return Request;
});

api.interceptors.response.use(
    (Response) =>
        // spinnerCtrl(false);
        Promise.resolve(Response),
    (error: Error | AxiosError) => {
        // spinnerCtrl(false);
        let response: AxiosResponse = {
            data: { errorMsg: 'API_ERROR.SYSTEM_ERROR' },
            status: 500,
            statusText: '',
            headers: undefined,
            config: {},
        };
        if (isAxiosError(error)) {
            const err = error as AxiosError;
            response = { ...response, ...err.response };
        }
        switch (response.status) {
            case 400:
            case 401:
            case 403:
            case 404:
            case 412:
            case 500:
                console.log('response status: ', response.status);
                break;
            default:
                break;
        }
        return Promise.reject(response);
    }
);

// {a:1,b:2, c:[1, 2,3]}
// a=1&b=2&c=1,2,3

export function toQueryString(
    paramsObject: {
        [key: string]: any;
    } = {}
) {
    if (!paramsObject) {
        return '';
    }

    return Object.keys(paramsObject)
        .filter(
            (key: string) =>
                paramsObject[key] !== '' &&
                paramsObject[key] !== null &&
                typeof paramsObject[key] !== 'undefined'
        )
        .map((key: string) =>
            Array.isArray(paramsObject[key])
                ? // convert to key=val1,val2,val3 string
                  `${key}=${paramsObject[key]
                      .map(
                          (val: string | number) => `${encodeURIComponent(val)}`
                      )
                      .join(',')}`
                : // convert to key=val string
                  `${key}=${encodeURIComponent(paramsObject[key])}`
        )
        .join('&');
}

/**
 * 替換Url中的變數，例如/v1/api/user/{id} 轉換成 /v1/api/user/5
 * @param {String} url API Url
 * @param {Object} body Query Parameters
 */
export function replaceUrl(url: string, body: { [key: string]: any }) {
    const regex = /(\{.+?\})/gi;
    return url.replace(regex, (v) => {
        const replacable = v[0] === '{';

        if (!replacable) {
            return v;
        }

        const propName = v.slice(1, -1);
        const replacedValue = body[propName];

        // eslint-disable-next-line no-param-reassign
        body[propName] = undefined;

        return replacedValue;
    });
}
