import { lazy } from 'react';
import * as PATH from './pathConstants';

const OverView = lazy(() => import('containers/OverView/OverView'));
const Login = lazy(() => import('containers/Login/Login'));
const KeywordExtraction = lazy(
    () => import('containers/KeywordExtraction/KeywordExtraction')
);
const KeywordHistory = lazy(
    () => import('containers/KeywordExtraction/KeywordHistory')
);
const KeywordRecord = lazy(
    () => import('containers/KeywordExtraction/KeywordRecord')
);

const TextAnalysis = lazy(() => import('containers/TextAnalysis/TextAnalysis'));

const TextAnalysisList = lazy(
    () => import('containers/TextAnalysis/TextAnalysisList')
);
const Redirect = lazy(() => import('containers/Redirect'));
const MindMap = lazy(() => import('containers/KeywordExtraction/MindMap'));

const DashBoardOverView = lazy(() => import('containers/DashBoards/OverView'));
const DashBoardKeyword = lazy(() => import('containers/DashBoards/Keywords'));
const DashBoardPartner = lazy(() => import('containers/DashBoards/Partner'));
const DatabaseOverview = lazy(
    () => import('containers/DatabaseOverview/DatabaseOverview')
);
const Sqm = lazy(() => import('containers/Sqm/Sqm'));
const BacktestAnalysis = lazy(
    () => import('containers/BacktestAnalysis/BacktestAnalysis')
);

export const FIRST_PATH = PATH.KEYWORD.EXTRACTION;

export default [
    {
        path: PATH.default.LOGIN,
        render: Login,
        auth: false,
        exact: true,
    },
    {
        path: PATH.default.OVERVIEW,
        render: OverView,
        auth: false,
        exact: true,
    },
    {
        path: PATH.KEYWORD.EXTRACTION,
        render: KeywordExtraction,
        auth: false,
        exact: true,
    },
    {
        path: PATH.KEYWORD.HISTORY,
        render: KeywordHistory,
        auth: false,
        exact: true,
    },
    {
        path: PATH.KEYWORD.RECORD,
        render: KeywordRecord,
        auth: false,
        exact: true,
    },
    {
        path: PATH.default.TEXT_ANALYSIS,
        render: TextAnalysis,
        auth: false,
        exact: true,
    },
    {
        path: PATH.default.TEXT_ANALYSIS_LIST,
        render: TextAnalysisList,
        auth: false,
        exact: true,
    },

    {
        path: PATH.KEYWORD.MIND_MAP,
        render: MindMap,
        auth: false,
        exact: true,
    },
    {
        path: PATH.default.DASHBOARD_OVERVIEW,
        render: DashBoardOverView,
        auth: false,
        exact: true,
    },
    {
        path: PATH.default.DASHBOARD_KEYWORD,
        render: DashBoardKeyword,
        auth: false,
        exact: true,
    },
    {
        path: PATH.default.DASHBOARD_PARTNER,
        render: DashBoardPartner,
        auth: false,
        exact: true,
    },
    {
        path: PATH.default.DATABASE,
        render: DatabaseOverview,
        auth: false,
        exact: true,
    },
    {
        path: PATH.default.SQM,
        render: Sqm,
        auth: false,
        exact: true,
    },
    {
        path: PATH.default.BACKTEST_ANALYSIS,
        render: BacktestAnalysis,
        auth: false,
        exact: true,
    },
];
