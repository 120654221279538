// tslint:disable no-implicit-dependencies
import React from 'react';
import { Modal, Row } from 'antd';
import { ModalFuncProps } from 'antd/es/modal';
import Icon, {
    ExclamationCircleFilled,
    DeleteOutlined,
} from '@ant-design/icons';
import { IconDone, IconWarning } from 'assets/image';
import Button from 'components/Button/Button';
import { intl } from '../assets/i18n';

// tslint:disable: quotemark

interface ModalInterface {
    destroy: () => any;
    update: (newConfig: ModalFuncProps) => any;
}

type Props = ModalFuncProps & {
    content: string | React.ReactNode;
    top?: string | number;
    duration?: number;
    icon?: React.ReactNode;
    useTimeout?: boolean;
};

/**
 * 根據參數的圖示跟標語，會產生一個純圖示文字的Confirm Modal
 * @param icon 提示訊息的圖示 (html tag, ex: antd <icon />)
 * @param content 標語(string)
 */
export function confirmModal({
    content,
    icon,
    top = '35%',
    duration = 500,
    maskClosable = false,
    ...props
}: Props) {
    const modal: ModalInterface = Modal.info({
        ...props,
        width: 349,
        style: {
            top,
            borderRadius: 12,
        },
        maskClosable,
        className: 'modal__info is--success',
        content: (
            <div className="d-flex is--column v--center">
                {icon}
                {content}
            </div>
        ),
        icon: false,
        okButtonProps: {
            style: { display: 'none' },
        },
    });

    setTimeout(() => modal.destroy(), duration);
}

export function success({
    content,
    top = '35%',
    duration = 3000,
    icon = <IconDone className="mb-20 d-block" />,
    maskClosable = false,
    ...props
}: Props) {
    const modal: ModalInterface = Modal.info({
        ...props,
        width: 349,
        style: {
            top,
            borderRadius: 12,
        },
        maskClosable,
        className: 'modal__info is--success',
        content: (
            <div className="d-flex is--column v--center">
                {icon}
                {content}
            </div>
        ),
        icon: false,
        okButtonProps: {
            style: { display: 'none' },
        },
    });

    setTimeout(() => modal.destroy(), duration);
}

export function error({
    content,
    top = '35%',
    duration = 3000,
    ...props
}: Props) {
    const modal: ModalInterface = Modal.info({
        ...props,
        width: 349,
        style: {
            top,
        },
        maskClosable: true,
        className: 'modal__info is--error',
        content: (
            <div className="text--center">
                <ExclamationCircleFilled
                    className="mb-20 d-block"
                    style={{
                        color: 'red',
                    }}
                />
                {content}
            </div>
        ),
        icon: false,
        okButtonProps: {
            style: { display: 'none' },
        },
    });

    setTimeout(() => modal.destroy(), duration);
}

export function warning({
    content,
    onOk,
    onCancel,
    top = '25%',
    icon = <Icon component={IconWarning} className="mb-20 d-block" />,
    ...props
}: Props) {
    let modal: ModalInterface;
    const handleOk = () => {
        if (onOk) {
            onOk();
        }

        if (modal) {
            modal.destroy();
        }
    };
    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }

        if (modal) {
            modal.destroy();
        }
    };
    modal = Modal.warn({
        width: 388,
        style: { top },
        className: 'modal__info is--warning',
        content: (
            <div className="text--center">
                {icon}
                {content}
                <Button
                    block
                    className="is--primary mt-32 mb-15"
                    onClick={handleOk}
                >
                    {intl().formatMessage({ id: 'COMMON.CONFIRM' })}
                </Button>
                {onCancel ? (
                    <Button
                        type="link"
                        block
                        onClick={handleCancel}
                        style={{ color: '#808996' }}
                    >
                        {intl().formatMessage({ id: 'COMMON.CANCEL' })}
                    </Button>
                ) : (
                    ''
                )}
            </div>
        ),
        icon: false,
        okButtonProps: {
            style: { display: 'none' },
        },
        ...props,
    });
}

export function serviceWarning({
    content,
    onOk,
    onCancel,
    top = '25%',
    icon = <Icon component={IconWarning} className="mb-20 d-block" />,
    ...props
}: Props) {
    const handleOk = () => {
        if (onOk) {
            onOk();
        }

        if (modal) {
            modal.destroy();
        }
    };

    let modal: ModalInterface = Modal.warn({
        width: 388,
        style: { top },
        className: 'modal__info is--warning',
        content: (
            <div className="text--center">
                {icon}
                {content}
                <Button
                    block
                    className="is--primary mt-32 mb-15"
                    onClick={handleOk}
                >
                    {intl().formatMessage({ id: 'COMMON.GOT_IT' })}
                </Button>
            </div>
        ),
        icon: false,
        okButtonProps: {
            style: { display: 'none' },
        },
        ...props,
    });
}

export function confirmToDelete({
    content,
    top = '35%',
    icon = (
        <DeleteOutlined
            className="d-block mb-20"
            style={{ color: '#E85050' }}
        />
    ),
    onOk,
    onCancel,
    ...props
}: Props) {
    const handleClose = () => {
        if (onCancel) {
            onCancel();
        }
        modal.destroy();
    };
    const handleOk = () => {
        if (onOk) {
            onOk();
        }
        modal.destroy();
    };
    let modal: ModalInterface = Modal.info({
        width: 360,
        style: {
            top,
            borderRadius: 12,
        },
        className: 'modal__info is--success',
        content: (
            <div>
                <div className="text--center mb-20">
                    {icon}
                    {content}
                </div>
                <Row className="mt-10">
                    <Button
                        className="d-inline-block width--full mr-20 ml-20"
                        style={{
                            backgroundColor: '#E85050',
                            border: '1px solid #AF0F0F',
                            color: 'white',
                        }}
                        onClick={handleOk}
                    >
                        {intl().formatMessage({ id: 'COMMON.CONFIRM_DELETE' })}
                    </Button>
                </Row>
                <Row className="mt-10">
                    <Button
                        type="link"
                        block
                        style={{ color: '#808996' }}
                        className="d-inline-block width--full mt-3 mr-20 ml-20"
                        onClick={handleClose}
                    >
                        {intl().formatMessage({ id: 'COMMON.CANCEL' })}
                    </Button>
                </Row>
            </div>
        ),
        icon: false,
        okButtonProps: {
            style: { display: 'none' },
        },
        ...props,
    });

    // setTimeout(() => modal.destroy(), duration);
}

export function warn({
    content,
    top = '25%',
    duration = 3000,
    useTimeout = true,
    width = 388,
    ...props
}: Props) {
    const modal: ModalInterface = Modal.warning({
        ...props,
        width,
        style: {
            top,
        },
        className: 'modal__info is--warning',
        content: (
            <div className="text--center">
                <Icon component={IconWarning} className="mb-20 d-block" />
                {content}
            </div>
        ),
        icon: false,
        okButtonProps: {
            style: { display: 'none' },
        },
    });
    if (useTimeout) {
        setTimeout(() => modal.destroy(), duration);
    }
    return modal;
}

export function nextCheck({
    title,
    content,
    onOk,
    onCancel,
    top = '35%',
    icon = <Icon component={IconDone} className="mb-20 d-block" />,
    ...props
}: Props) {
    const handleOk = () => {
        if (onOk) {
            onOk();
        }

        if (modal) {
            modal.destroy();
        }
    };

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }

        if (modal) {
            modal.destroy();
        }
    };

    let modal: ModalInterface = Modal.warn({
        width: 388,
        style: { top },
        className: 'modal__info is--warning',
        content: (
            <div className="text--center">
                {icon}
                <div className="mb-15" style={{ fontSize: '20px' }}>
                    {title}
                </div>
                <div className="mb-15" style={{ color: '#808996' }}>
                    {content}
                </div>
                <Button
                    block
                    className="is--primary mt-32 mb-15"
                    onClick={handleOk}
                >
                    {intl().formatMessage({
                        id: 'ROLE_MGMT.SETTING_PERMISSION',
                    })}
                </Button>
                <Button
                    type="link"
                    block
                    onClick={handleCancel}
                    style={{ color: '#808996' }}
                >
                    {intl().formatMessage({ id: 'COMMON.NEXT_TIME' })}
                </Button>
            </div>
        ),
        icon: false,
        okButtonProps: {
            style: { display: 'none' },
        },
        ...props,
    });
}

export function confirm({
    content,
    onOk,
    onCancel,
    top = '25%',
    icon,
    width = 388,
    ...props
}: Props) {
    const handleOk = () => {
        if (onOk) {
            onOk();
        }

        if (modal) {
            modal.destroy();
        }
    };

    let modal: ModalInterface = Modal.warn({
        width,
        style: { top },
        className: 'modal__info',
        content: (
            <div className="text--center">
                {icon}
                {content}
                <Button
                    block
                    className="is--primary mt-32 mb-15"
                    onClick={handleOk}
                >
                    {intl().formatMessage({ id: 'COMMON.CONFIRM' })}
                </Button>
            </div>
        ),
        icon: false,
        okButtonProps: {
            style: { display: 'none' },
        },
        ...props,
    });
}

export function message({
    content,
    onOk,
    onCancel,
    top = '25%',
    width = 490,
    ...props
}: Props) {
    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }

        if (modal) {
            modal.destroy();
        }
    };

    let modal: ModalInterface = Modal.warn({
        width,
        style: { top },
        className: 'modal__info',
        content: (
            <div className="text--center">
                {content}
                <Button
                    type="link"
                    block
                    style={{ color: '#808996' }}
                    className="d-inline-block width--full"
                    onClick={handleCancel}
                >
                    {intl().formatMessage({ id: 'COMMON.CANCEL' })}
                </Button>
            </div>
        ),
        icon: false,
        okButtonProps: {
            style: { display: 'none' },
        },
        ...props,
    });
}

export function confirmAndcancel({
    content,
    onOk,
    onCancel,
    top = '25%',
    icon,
    width = 388,
    ...props
}: Props) {
    const handleOk = () => {
        if (onOk) {
            onOk();
        }

        if (modal) {
            modal.destroy();
        }
    };
    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }

        if (modal) {
            modal.destroy();
        }
    };

    let modal: ModalInterface = Modal.warn({
        width,
        style: { top },
        className: 'modal__info',
        content: (
            <div className="text--center">
                {content}
                <Button
                    block
                    className="is--primary mt-32 mb-15"
                    onClick={handleOk}
                >
                    {intl().formatMessage({ id: 'COMMON.CONFIRM' })}
                </Button>
                <Button
                    type="link"
                    block
                    style={{ color: '#808996' }}
                    className="d-inline-block width--full"
                    onClick={handleCancel}
                >
                    {intl().formatMessage({ id: 'COMMON.CANCEL' })}
                </Button>
            </div>
        ),
        icon: false,
        okButtonProps: {
            style: { display: 'none' },
        },
        ...props,
    });
}

export function rentalDeviceQrModal({
    content,
    onOk,
    onCancel,
    top = '25%',
    width = 490,
    ...props
}: Props) {
    const handleOk = () => {
        if (onOk) {
            onOk();
        }

        if (modal) {
            modal.destroy();
        }
    };
    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }

        if (modal) {
            modal.destroy();
        }
    };

    let modal: ModalInterface = Modal.warn({
        width,
        style: { top },
        className: 'modal__info',
        content: (
            <div className="text--center">
                {content}
                <Button
                    block
                    className="is--primary mt-32 mb-15"
                    onClick={handleOk}
                >
                    {intl().formatMessage({
                        id: 'DEVICE_MGMT.SWITCH_MANUAL_RENTAL',
                    })}
                </Button>
                <Button
                    type="link"
                    block
                    style={{ color: '#808996' }}
                    className="d-inline-block width--full"
                    onClick={handleCancel}
                >
                    {intl().formatMessage({ id: 'COMMON.CANCEL' })}
                </Button>
            </div>
        ),
        icon: false,
        okButtonProps: {
            style: { display: 'none' },
        },
        ...props,
    });
}

export function returnDeviceQrModal({
    content,
    onOk,
    onCancel,
    top = '25%',
    width = 490,
    ...props
}: Props) {
    const handleOk = () => {
        if (onOk) {
            onOk();
        }

        if (modal) {
            modal.destroy();
        }
    };
    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }

        if (modal) {
            modal.destroy();
        }
    };

    let modal: ModalInterface = Modal.warn({
        width,
        style: { top },
        className: 'modal__info',
        content: (
            <div className="text--center">
                {content}
                <Button
                    block
                    className="is--primary mt-32 mb-15"
                    onClick={handleOk}
                >
                    {intl().formatMessage({
                        id: 'DEVICE_MGMT.SWITCH_MANUAL_RETURN',
                    })}
                </Button>
                <Button
                    type="link"
                    block
                    style={{ color: '#808996' }}
                    className="d-inline-block width--full"
                    onClick={handleCancel}
                >
                    {intl().formatMessage({ id: 'COMMON.CANCEL' })}
                </Button>
            </div>
        ),
        icon: false,
        okButtonProps: {
            style: { display: 'none' },
        },
        ...props,
    });
}
