/* eslint-disable jsx-a11y/aria-role */
import React, { useEffect, useContext, Suspense, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Drawer } from 'antd';
import {
    Redirect,
    Route,
    withRouter,
    useHistory,
    Switch,
} from 'react-router-dom';
import Header from 'components/Header/Header';
import { Navigation, LoadingBar } from 'components';
import { GlobalContext } from 'store/appStore';
import { apiCaller } from 'service/apiCaller';
import useModal from 'hooks/useModal';
import ChangePasswordDrawer from 'components/ChangePassword/ChangePasswordDrawer';
import { jwtParse } from 'service/jwtParse';
import { routeMatch } from 'utils/webHelper';
import { STORAGE_KEY } from 'constants/index';
import { getUserInfo } from 'api/userApi';
import config, { FIRST_PATH } from './routerConfig';
import PATH from './pathConstants';

const Main = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    height: calc(100vh - 50px);
    overflow: hidden;
`;

export const WaitingComponent = (Component: any) => (props: any) =>
    (
        <Suspense fallback={<div>Module loading....</div>}>
            <Component {...props} />
        </Suspense>
    );

const RouterGuard: React.FC<any> = () => {
    const intl = useIntl();
    const history = useHistory();
    const notificationModal = useModal();
    const changePasswordModal = useModal();
    const { pathname } = window.location;
    // const token = localStorage.getItem('token');
    // const token =
    //     'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJBSU9UIiwicGVybWlzc2lvbnMiOlsiUEVSTUlTU0lPTl9NR00iLCJUUklHR0VSX1JVTEUiLCJBQ0NPVU5UX01HTSIsIkRFVklDRV9UWVBFX01HTSIsIkRFVklDRV9NR00iLCJGSVJNV0FSRV9NR00iLCJUUklHR0VSX0FDVElPTiIsIkNBTUVSQV9NQVAiLCJUUklHR0VSX1RFTVBMQVRFIiwiR1JPVVBTX0RFVklDRV9NR00iLCJTVEFGRklOR19NR00iLCJPVEFfTUdNIiwiQUlSX1BST0dSQU0iLCJFQ1VfTUdNIiwiTU9CSUxFX0RFVklDRV9NR00iLCJBSVJfT1RBX01HTSIsIkRFVklDRV9DT05GSUciLCJWRU5ET1JfTUdNIiwiREVQQVJUTUVOVF9NR00iLCJERVZJQ0VfTUFQIiwiVkVISUNMRV9UWVBFX01HTSIsIlRFTkFOVF9NR00iLCJWRUhJQ0xFX01HTSJdLCJ1c2VyQWNjb3VudCI6IkFJT1RfQURNSU5JU1RSQVRPUiIsImlzcyI6Ildpc3Ryb24gU1dQQyIsInRlbmFudElkIjoiZjg3YjkzYWUtYWJhNi00ODQwLTgyNTEtNDdjMGRiNmU5OThiIiwiaXNWZW5kb3IiOmZhbHNlLCJ1c2VyQWNjb3VudFR5cGUiOiJXRUIiLCJleHAiOjE2NDE1OTA1MzMsInVzZXJJZCI6ImE3M2YwNWRlLTBkOTMtNDljMS04YWRhLTU0ZTc1NjkyYjQ2MCIsImlhdCI6MTY0MTU0NzMzMywianRpIjoiMGIyYmJkYTctZTMwNy00MDlhLWEwOTUtMTljOGIyNzRmMzZjIn0.Db7IgsCxTR31OBnhZmprG8k1Js5y2Q9evVdSChI0kow';
    // const isLogin = jwtParse(token); // 要作jtw金鑰檢查，是否為正確的token
    const targetRouter = config.find(
        (v: any) => !!routeMatch(v.path, pathname)
    );
    const [isLogin, setIsLogin] = useState(false);
    const {
        permissionCtx,
        setPermissionCtx,
        closableMenu,
        visibleMenu,
        setVisibleMenu,
        userInfo,
        login,
    } = useContext(GlobalContext);

    useEffect(() => {
        // TODO 重storage補userInfo
        if (targetRouter && !targetRouter.auth) {
            return;
        }
        const token = localStorage.getItem(STORAGE_KEY.TOKEN);
        const success = (res: any) => {
            const {
                httpCode,
                result: { data },
            } = res.data;
            if (httpCode === 200) {
                const userO = {
                    userAccount: data.userAccount,
                    userId: data.userId,
                    userName: data.userName,
                    token,
                };
                login(userO);
            }
        };
        if (token !== null && token !== userInfo.token) {
            apiCaller(getUserInfo, {}, success, (res: any) => {
                console.log(res);
            });
        } else {
            setIsLogin(jwtParse(userInfo.token));
        }
    }, [userInfo]);

    const getMenuComp = () => {
        const role =
            process.env.REACT_APP_DASHBOARD_ONLY === 'true'
                ? 'GUEST'
                : 'MANAGER';

        return <Navigation mode="inline" role={role} />;
    };
    // 檢查登入資訊
    if (isLogin) {
        // 已登入，就不可以再導向登入頁
        if (pathname === PATH.LOGIN) {
            return <Redirect to={FIRST_PATH} />;
        }
        if (targetRouter) {
            const component = (
                <>
                    <LoadingBar />
                    <Header
                        needAuth
                        onClickNotification={() =>
                            notificationModal.visible
                                ? notificationModal.closeModal()
                                : notificationModal.openModal()
                        }
                        onClickChangePassword={() =>
                            changePasswordModal.visible
                                ? changePasswordModal.closeModal()
                                : changePasswordModal.openModal()
                        }
                        notificationVisiable={notificationModal.visible}
                    />
                    <Main>
                        {closableMenu ? (
                            <Drawer
                                placement="left"
                                bodyStyle={{ padding: 0 }}
                                closable={false}
                                maskClosable
                                visible={visibleMenu}
                                onClose={() => setVisibleMenu(false)}
                                getContainer={false}
                                style={{ position: 'absolute', zIndex: 1004 }}
                            >
                                {getMenuComp()}
                            </Drawer>
                        ) : (
                            getMenuComp()
                        )}
                        <Switch>
                            {config.map((con) => (
                                <Route
                                    {...con}
                                    key={`route_${con.path}`}
                                    exact
                                    render={WaitingComponent(con.render)}
                                />
                            ))}
                        </Switch>
                    </Main>
                    <ChangePasswordDrawer
                        visable={changePasswordModal.visible}
                        onClose={() => changePasswordModal.closeModal()}
                    />
                </>
            );
            return component;
        }
    }

    // 不須登入頁面先處理
    if (targetRouter && !targetRouter.auth) {
        const { render } = targetRouter;
        return (
            <>
                <Header />
                <Main>
                    {closableMenu ? (
                        <Drawer
                            placement="left"
                            bodyStyle={{ padding: 0 }}
                            closable={false}
                            maskClosable
                            visible={visibleMenu}
                            onClose={() => setVisibleMenu(false)}
                            getContainer={false}
                            style={{ position: 'absolute', zIndex: 1004 }}
                        >
                            {getMenuComp()}
                        </Drawer>
                    ) : (
                        getMenuComp()
                    )}
                    <Switch>
                        <Route
                            exact
                            path={pathname}
                            render={WaitingComponent(render)}
                        />
                    </Switch>
                </Main>
            </>
        );
    }
    // return <Redirect to="/" />; // 未登入，則一律跳轉至登入頁
    return <Redirect to="/app/Keyword/Extraction" />;
    // return <Redirect to="/function-list/patientTo" />;
};

export default withRouter(RouterGuard);
