const BASE = '/app';
export const PREFIX = process.env.REACT_APP_PUBLIC_URL
    ? `/${process.env.REACT_APP_PUBLIC_URL}`
    : '';

export const KEYWORD = {
    EXTRACTION: `${BASE}/Keyword/Extraction`,
    HISTORY: `${BASE}/Keyword/History`,
    RECORD: `${BASE}/Keyword/Records`,
    MIND_MAP: `${BASE}/Keyword/MindMap`,
};

const PATH = {
    LOGIN: `${BASE}/login`,
    SQM: `${BASE}/Sqm/Sqm`,
    OVERVIEW: `${BASE}/overview`,
    ANALYSIS: `${BASE}/Analysis`,
    TEXT_ANALYSIS: `${BASE}/Analysis/Text_Analysis`,
    TEXT_ANALYSIS_LIST: `${BASE}/Analysis/Text_Analysis_List`,
    ...KEYWORD,
    DASHBOARD_OVERVIEW: `${BASE}/dashboard/OverView`,
    DASHBOARD_KEYWORD: `${BASE}/dashboard/Keywords`,
    DASHBOARD_PARTNER: `${BASE}/dashboard/Partner`,
    DATABASE: `${BASE}/Database/Database`,
    BACKTEST_ANALYSIS: `${BASE}/backtest_analysis/backtest_analysis`,
};

export const keyMap = Object.keys(PATH).reduce(
    (prv, cur) => ({ ...prv, [PATH[cur]]: cur }),
    {}
);

export default PATH;
