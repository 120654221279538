import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import type { MenuProps } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { Menu } from 'antd';
import { menuItemAll } from 'constants/index';
import { keyMap } from 'router/pathConstants';
import { getMenu } from './MenuMapping';
import './Navigation.sass';

const { SubMenu } = Menu;

type MenuMode = 'vertical' | 'horizontal' | 'inline';

interface NavigationProps {
    role: string;
    mode: MenuMode;
}

type T_MenuInfo = {
    firstPage: string;
    menus: any[];
};
export default function Navigation({ role, mode }: NavigationProps) {
    const intl = useIntl();
    const history = useHistory();
    const [menuInfo, setMenuInfo] = useState<T_MenuInfo>({
        firstPage: '/',
        menus: [],
    });

    const domain = window.location.pathname.split('/');

    let routeKey = process.env.REACT_APP_PUBLIC_URL
        ? keyMap[`/${domain[2]}/${domain[3]}`]
        : keyMap[`/${domain[1]}/${domain[2]}`];
    // const domainKey = keyMap[`/${domain[2]}`];
    // level 1
    const domainKey = process.env.REACT_APP_PUBLIC_URL
        ? keyMap[`/${domain[2]}`]
        : keyMap[`/${domain[1]}`];
    const [selectedKey, setSelectedKey] = useState<any>(['KEYWORD_EXTRACTION']); // 選單選中
    const [openKey, setOpenKey] = useState<any>([]); // 選單focus
    const [menuItem, setMenuItem] = useState<any>([]);
    const newMenus = getMenu(role, {});
    useEffect(() => {
        // convert menus
        const items: MenuProps['items'] = newMenus.menus.map(
            (menu1Obj: any) => {
                let children;
                if (menu1Obj.menu.length > 0) {
                    children = menu1Obj.menu.map((subObj: any) => ({
                        label: intl.formatMessage({ id: subObj.i18n }),
                        key: subObj.key,
                        onClick: (e: any) => {
                            history.push(subObj.path);
                        },
                    }));
                }
                return {
                    label: intl.formatMessage({ id: menu1Obj.i18n }),
                    key: menu1Obj.key,
                    icon: menu1Obj.icon,
                    children,
                    onClick:
                        menu1Obj.menu.length === 0 && menu1Obj.path !== null
                            ? () => history.push(menu1Obj.path)
                            : undefined,
                };
            }
        );
        setMenuInfo({
            firstPage: newMenus.firstPage,
            menus: items,
        });
        // console.log('w');
    }, [role]);

    useEffect(() => {
        routeKey = process.env.REACT_APP_PUBLIC_URL
            ? keyMap[`/app/${domain[2]}/${domain[3]}`]
            : keyMap[`/app/${domain[2]}/${domain[3]}`];
        const findKey = newMenus.menus.find(
            (item) => item.key.toLowerCase() === domain[2].toLowerCase()
        );
        if (findKey) {
            const res = findKey.menu.find(
                (ele: any) => ele.path === `/app/${domain[2]}/${domain[3]}`
            );
            if (res !== undefined) {
                console.log(findKey);
                setSelectedKey([`${res.key}`]);
                setOpenKey([`${domain[2].toUpperCase()}`]);
            }
        }
    }, [window.location.pathname]);

    const getOpenKeyBySubKey = (subKey: string | React.Key, menu?: any) => {
        const thisMenuItem = menu || menuItem;
        let openMenuKey: any[] = [];
        thisMenuItem.forEach((item: any) => {
            // level 1
            item.subMenu.forEach((sub1: any) => {
                if (sub1.id === subKey) {
                    openMenuKey = [item.id];
                } else if (sub1.sub) {
                    sub1.sub.forEach((sub2: any) => {
                        if (sub2.id === subKey) {
                            openMenuKey = [item.id, sub1.id];
                        }
                    });
                }
            });
        });
        return openMenuKey;
    };

    const setMenu = () => {
        // const permissionMenu = Object.keys(permission || {}).filter((item: any) => Object.keys(permission[item]).length > 0);
        // let menuItem = [
        //     ...permissionMenu.map((item: string) => find(propEq('id', item))(menuItemAll))
        // ]
        // setMenuItem(menuItem.filter((item: any) => item)); // remove undefined/null item
        const permissionMenu = menuItemAll.map((item: any) => ({
            ...item,
            subMenu: item.subMenu,
            // subMenu: item.subMenu.filter((sub) => permission.includes(sub.id)),
        }));
        const nextMenuItem = permissionMenu.filter(
            (item) => item.subMenu.length
        );
        const openMenuKey = getOpenKeyBySubKey(routeKey, nextMenuItem);
        setMenuItem(nextMenuItem); // remove undefined/null item
        setOpenKey(openMenuKey.length ? [...openMenuKey] : [routeKey]);
    };

    const handleSelect = (key: React.Key) => {
        setSelectedKey(key);
        const openMenuKey = getOpenKeyBySubKey(key);
        setOpenKey(openMenuKey.length ? [...openMenuKey] : [key]);
    };

    const onOpenChange = (keys: any[]) => {
        const latestOpenKey = keys.find(
            (key: any) => openKey.indexOf(key) === -1
        );
        if (menuItem.indexOf(latestOpenKey) === -1) {
            setOpenKey(keys);
        } else {
            setOpenKey(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    return (
        <Menu
            style={{ height: '100%', width: '200px' }}
            items={menuInfo.menus}
            mode={mode}
            openKeys={openKey}
            selectedKeys={selectedKey}
            onOpenChange={onOpenChange}
        />
    );
}
